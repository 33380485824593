import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfBrand from "@/vue/domain/brand/df-brand";

@Component
export default class DfSetComponent extends Vue {
  @Prop({ type: String, default: "df-panel" })
  id!: string;
  @Prop({ type: Array })
  options!: Array<any>;
  @Prop({ type: Array })
  activeOptions!: Array<any>;
  @Prop({ type: String, default: "code" })
  code!: string;
  @Prop({ type: String, default: "name" })
  label!: string;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get enabledMultiTagSearch(): boolean {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_ENABLE_MULTI_TAG_FILTER, "BOOLEAN", true);
  }

  get hasSelection(): boolean {
    return this.optionSet.filter((option: any) => option.selected).length > 0;
  }

  get optionSet(): Array<any> {
    return (this.options || []).map((option) => {
      return {
        code: option[this.code],
        name: option[this.label],
        selected: this.activeOptions.findIndex((activeOption) => activeOption[this.code] === option[this.code]) !== -1,
      };
    });
  }

  private resetSelectedOptions(): void {
    this.$emit("updateSelectedOptions", []);
  }

  private toggleSelection(code: string): void {
    const selectedOption = this.optionSet.find((option) => {
      return option.code === code;
    });
    if (selectedOption != null) {
      selectedOption.selected = !selectedOption.selected;
      const options: Array<any> = this.enabledMultiTagSearch ? this.optionSet.filter((option: any) => option.selected) : this.optionSet.filter((option: any) => option.code === selectedOption.code && option.selected);
      this.$emit("updateSelectedOptions", options);
    }
  }
}
